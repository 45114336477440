<template>
  <v-container>
    <CrudTable
      tabelaKey="id"
      :tabelaComDetalhes="true"
      :headers="headers"
      :items="parceiros"
      :pagination="pagination"
      :loading="carregandoAcao"
      :keyPaginacao="keyPaginacao"
      @actualPage="page"
      @clickRow="detalharParceiro($event)"
    >
      <template v-slot:top>
        <v-toolbar flat :class="$vuetify.breakpoint.xs ? 'scroll' : ''">
          <v-spacer></v-spacer>

          <v-dialog
            max-width="720px"
            persistent
            :value="exibirDialogFiltros"
          >
            <template v-slot:activator="{}">
              <div class="d-flex">
                <ButtonMain
                  @click.native="abrirDialogFiltroParceiro()"
                  text="filtrar"
                  :primary="true"
                ></ButtonMain>

                <ButtonMain
                  v-if="filtroAplicado"
                  text="Limpar filtros"
                  :primary="true"
                  class="ml-2 warning"
                  @click.native="
                    limparFiltro()
                    filtroAplicado = false
                  "
                ></ButtonMain>
              </div>
            </template>
            <v-card class="overflow-hidden">
              <v-toolbar flat>
                <v-toolbar-title class="font-weight-light">
                  Aplicar filtros
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field
                      hide-details="auto"
                      outlined
                      label="Nome Fantasia"
                      v-model="filtro.razaoSocial"
                    ></v-text-field>
                  </v-col>

                  <InputMaskField
                    label="Filtrar por CNPJ"
                    maskFormat="##.###.###/####-##"
                    @update:value="filtro.cnpj = $event"
                    :valueField="filtro.cnpj"
                    :cols="6"
                  />

                  <SelectField
                    label="Filtrar por Status"
                    keyValue="id"
                    keyName="descricao"
                    :items="listaStatusParceiro"
                    :cols="6"
                    :valueField="filtro.status"
                    @update:value="filtro.status = $event"
                  />
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                  color="red darken-1"
                  text
                  @click="exibirDialogFiltros = false"
                  >Cancelar</v-btn
                >
                <v-btn
                  color="blue darken-1"
                  text
                  @click="
                    aplicarFiltro()
                    exibirDialogFiltros = false
                  "
                  >filtrar</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:[`item.cnpj`]="{ item }">
        <span>{{ item.cnpj | VMask('##.###.###/####-##') }}</span>
      </template>

      <template v-slot:[`item.produto`]="{ item }">
        <span>{{ item.produto ? 'SIM' : 'NÃO' }}</span>
      </template>

      <template v-slot:[`item.idStatus`]="{ item }">
        <StatusChip :color="getColor(item.idStatus)">
          {{ item.descricaoStatus ? item.descricaoStatus : 'NÃO INFORMADO' }}
        </StatusChip>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-dialog width="600">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              v-bind="attrs"
              v-on="on"
              color="green"
              class="mr-2"
              :loading="item.idStatus !== 2 && item.atualizando"
              :disabled="item.idStatus === 2 || item.atualizando"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small color="white" v-bind="attrs" v-on="on">
                    mdi-check-circle
                  </v-icon>
                </template>
                <span>Tornar Ativo</span>
              </v-tooltip>
            </v-btn>
          </template>

          <template v-slot:default="dialog">
            <v-card>
              <v-card-title color="primary">
                Tem certeza que deseja alterar o status?
              </v-card-title>
              <v-divider></v-divider>

              <v-card-text class="text-5 body-1 my-6">
                <div class="text--primary">
                  {{
                    `Essa ação mudará o status do parceiro ${item.nome} para
                    ATIVO. Isso permitirá seu uso da plataforma de vendas. Deseja continuar? `
                  }}
                </div>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="red darken-1" text @click="dialog.value = false"
                  >Cancelar</v-btn
                >
                <v-btn
                  color="blue darken-1"
                  text
                  @click="
                    alterarStatusParceiro(item, 2)
                    dialog.value = false
                  "
                  >Confirmar</v-btn
                >
              </v-card-actions>
            </v-card>
          </template></v-dialog
        >

        <v-dialog width="660">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              v-bind="attrs"
              v-on="on"
              class="mr-2"
              :color="item.produto ? 'red' : 'blue'"
              :loading="item.ativandoProdutos"
              :disabled="(item.idStatus !== 2 || item.ativandoProdutos)"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small color="white" v-bind="attrs" v-on="on">
                    {{ item.produto ? 'mdi-store-remove' : 'mdi-store-check'}}
                  </v-icon>
                </template>
                <span>{{ item.produto ? 'Desativar' : 'Ativar'}} venda por produtos</span>
              </v-tooltip>
            </v-btn>
          </template>

          <template v-slot:default="dialog">
            <v-card>
              <v-card-title color="primary">
                Tem certeza que deseja alterar a permissão de venda de produtos para este parceiro?
              </v-card-title>
              <v-divider></v-divider>

              <v-card-text class="text-5 body-1 my-6">
                <div v-if="item.produto" class="text--primary">
                  {{
                    `Esta ação removerá a permissão de venda por produtos
                    do parceiro ${item.nome}. Deseja continuar?`
                  }}
                </div>

                <div v-if="!item.produto" class="text--primary">
                  {{
                    `Esta ação permitirá que o parceiro ${item.nome}
                    realize venda por produtos. Deseja continuar?`
                  }}
                </div>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="red darken-1" text @click="dialog.value = false"
                  >Cancelar</v-btn
                >
                <v-btn
                  color="blue darken-1"
                  text
                  @click="
                    alterarPermisaoVendaProdutos(item)
                    dialog.value = false
                  "
                  >Confirmar</v-btn
                >
              </v-card-actions>
            </v-card>
          </template></v-dialog
        >

        <v-dialog width="600"
          ><template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              v-bind="attrs"
              v-on="on"
              color="red"
              class="white--text"
              :loading="item.idStatus === 2 && item.atualizando"
              :disabled="item.idStatus !== 2 || item.atualizando"
            >
              <v-tooltip top>
                <template small v-slot:activator="{ on, attrs }">
                  <v-icon small color="white" v-bind="attrs" v-on="on">
                    mdi-close-circle
                  </v-icon>
                </template>
                <span>Tornar Inativo</span>
              </v-tooltip>
            </v-btn>
          </template>

          <template v-slot:default="dialog">
            <v-card>
              <v-card-title color="primary">
                Tem certeza que deseja alterar o status?
              </v-card-title>
              <v-divider></v-divider>

              <v-card-text class="text-5 body-1 my-6">
                <div class="text--primary">
                  {{
                    `Essa ação mudará o status do parceiro ${item.nome} para
                    INATIVO. Isso revogorá sua permissão de uso da plataforma de vendas. Deseja continuar?`
                  }}
                </div>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="dialog.value = false"
                  >Cancelar</v-btn
                >
                <v-btn
                  color="blue darken-1"
                  text
                  @click="
                    alterarStatusParceiro(item, 3)
                    dialog.value = false
                  "
                  >Confirmar</v-btn
                >
              </v-card-actions>
            </v-card>
          </template></v-dialog
        >
      </template>
    </CrudTable>
  </v-container>
</template>

<script>
import parceiroService from '@/services/ParceiroService'
import TitleH2 from '@/components/atoms/title/TitleH2'
import CrudTable from '@/components/molecules/tables/CrudTable'
import ButtonMain from '@/components/atoms/buttons/ButtonMain.vue'
import DialogConfirmation from '@/components/molecules/dialogs/DialogConfirmation.vue'
import FormField from '@/components/atoms/forms/FormField.vue'
import StatusChip from '@/components/atoms/StatusChip.vue'
import InputMaskField from '@/components/atoms/fields/InputMaskField.vue'
import SelectField from '@/components/atoms/fields/SelectField.vue'

export default {
  components: {
    TitleH2,
    CrudTable,
    ButtonMain,
    FormField,
    DialogConfirmation,
    StatusChip,
    InputMaskField,
    SelectField,
  },

  data() {
    return {
      keyPaginacao: 0,
      mensagemErroPadrao: 'Falha na conexão. Por favor, tente mais tarde.',
      filtroAplicado: false,
      exibirDialogFiltros: false,
      carregandoAcao: false,
      filtro: {
        razaoSocial: '',
        cnpj: '',
        status: '',
      },
      keyDialog: '',
      parceiros: [],
      listaStatusParceiro: [],
      headers: [
        { text: 'Nome Fantasia', value: 'nome', width: 350, sortable: false },
        { text: 'CNPJ', value: 'cnpj', align: 'center', sortable: false },
        { text: 'Telefone', value: 'telefone', align: 'center', sortable: false },
        { text: 'Cidade', value: 'cidade', align: 'center', sortable: false },
        { text: 'Venda por Produtos', value: 'produto', width: 110, align: 'center', sortable: false },
        { text: 'Status', align: 'center', value: 'idStatus', sortable: false },
        { text: '', value: 'actions', width: 200, sortable: false }
      ],
      validacao: 0,
      itensPorPagina: 10,
      pagination: {
        page: 0,
        perPage: 10,
        total: 0,
      }
    }
  },

  created() {
    this.receberStatusParceiro();
    this.receberParceiros({ page: this.pagination.page, size: this.itensPorPagina });
  },

  methods: {
    receberParceiros(paginacao) {
      this.carregandoAcao = true;

      parceiroService
        .filtroParceiro({...paginacao, ...this.filtro })
        .then(({ data }) => {
          this.parceiros = data.content.map(parceiro => {
              return {
                ...parceiro,
                telefone: this.setTelefone(parceiro.ddd, parceiro.telefone),
                cidade: parceiro.nomeCidade,
                atualizando: false,
                ativandoProdutos: false,
              }
            });
          this.pagination.total = data.totalPages
        })
        .catch((erro) => {
          const mensagemErro = erro.response.data;
          this.$root.vtoast.show({
            status: 'error',
            title:  mensagemErro.titulo || this.mensagemErroPadrao,
            text: mensagemErro.mensagemUsuario || '',
          });
        })
        .then(() => this.carregandoAcao = false);
    },

    receberStatusParceiro() {
      parceiroService.statusParceiro().then(({ data }) => this.listaStatusParceiro = data);
    },

    resetarPaginacao() {
      ++this.keyPaginacao;
    },

    setTelefone(ddd, telefone) {
      if(ddd && telefone) return `(${ddd}) ${telefone}`;
      if(!ddd && telefone) return `${telefone}`;
      return null;
    },

    getColor(status) {
      if (status === 1) return 'orange';
      if (status === 2) return 'green';
      if (status === 3) return 'red';
      return '';
    },

    abrirDialogFiltroParceiro() {
      this.filtro = { razaoSocial: '', cnpj: '', status: '' };
      this.exibirDialogFiltros = true;
    },

    detalharParceiro(item) {
      this.$router.push({ name: 'DetalheParceiro', params: { id: item.id } })
    },

    alterarStatusParceiro(parceiro, statusId) {
      parceiro.atualizando = true;

      const body = {
        cnpj: parceiro.cnpj,
        idStatus: statusId,
      };

      parceiroService
        .alterarStatusParceiro(body)
        .then(() => {
          this.$root.vtoast.show({
            status: 'success',
            text: 'Status atualizado.',
            title: 'SUCESSO!'
          });
          this.receberParceiros({ page: this.pagination.page, size: this.itensPorPagina });
        })
        .catch((erro) => {
          const mensagemErro = erro.response.data;
          this.$root.vtoast.show({
            status: 'error',
            title:  mensagemErro.titulo || this.mensagemErroPadrao,
            text: mensagemErro.mensagemUsuario || '',
          });
        })
        .then(() => parceiro.atualizando = false );
    },

    alterarPermisaoVendaProdutos(parceiro) {
      parceiro.ativandoProdutos = true;

      const body = {
        cnpj: parceiro.cnpj,
        vincular: !parceiro.produto,
      };

      parceiroService
        .alterarPermisaoVendaProdutos(body)
        .then(() => {
          this.$root.vtoast.show({
            status: 'success',
            text: 'Permissão atualizada.',
            title: 'SUCESSO!'
          });

          this.receberParceiros({ page: this.pagination.page, size: this.itensPorPagina });
        })
        .catch((erro) => {
          const mensagemErro = erro.response.data;
          this.$root.vtoast.show({
            status: 'error',
            title:  mensagemErro.titulo || this.mensagemErroPadrao,
            text: mensagemErro.mensagemUsuario || '',
          });
        })
        .then(() => parceiro.ativandoProdutos = false );
    },

    aplicarFiltro() {
      this.resetarPaginacao();
      this.filtroAplicado = true;
      this.pagination.page = 1;
      this.receberParceiros({ page: 0, size: 10 });
    },

    limparFiltro() {
      this.resetarPaginacao();
      this.filtro = { razaoSocial: '', cnpj: '', status: '' };
      this.pagination.page = 0;
      this.receberParceiros({ page: 0, size: this.itensPorPagina });
    },

    filtroParceiro(paginacao) {
      parceiroService
        .filtroParceiro({...paginacao, ...this.filtro })
        .then(({ data }) => {
          this.parceiros = data.content.map(parceiro => {
              return {
                ...parceiro,
                telefone: this.setTelefone(parceiro.ddd, parceiro.telefone),
                cidade: parceiro.nomeCidade
              }
            });
          this.pagination.total = data.totalPages
        })
        .catch((erro) => {
          const mensagemErro = erro.response.data;
          this.$root.vtoast.show({
            status: 'error',
            title:  mensagemErro.titulo || this.mensagemErroPadrao,
            text: mensagemErro.mensagemUsuario || '',
          });
        })
        .then(() => this.carregandoAcao = false);
    },

    page(page) {
      this.pagination.page = page - 1;
      this.receberParceiros({ page: this.pagination.page, size: this.itensPorPagina });
    },
  },
}
</script>

<style scoped>
  .scroll {
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
  }
</style>
