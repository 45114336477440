var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('CrudTable',{attrs:{"tabelaKey":"id","tabelaComDetalhes":true,"headers":_vm.headers,"items":_vm.parceiros,"pagination":_vm.pagination,"loading":_vm.carregandoAcao,"keyPaginacao":_vm.keyPaginacao},on:{"actualPage":_vm.page,"clickRow":function($event){return _vm.detalharParceiro($event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{class:_vm.$vuetify.breakpoint.xs ? 'scroll' : '',attrs:{"flat":""}},[_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"720px","persistent":"","value":_vm.exibirDialogFiltros},scopedSlots:_vm._u([{key:"activator",fn:function(ref){return [_c('div',{staticClass:"d-flex"},[_c('ButtonMain',{attrs:{"text":"filtrar","primary":true},nativeOn:{"click":function($event){return _vm.abrirDialogFiltroParceiro()}}}),(_vm.filtroAplicado)?_c('ButtonMain',{staticClass:"ml-2 warning",attrs:{"text":"Limpar filtros","primary":true},nativeOn:{"click":function($event){_vm.limparFiltro()
                  _vm.filtroAplicado = false}}}):_vm._e()],1)]}}])},[_c('v-card',{staticClass:"overflow-hidden"},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"font-weight-light"},[_vm._v(" Aplicar filtros ")])],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{attrs:{"hide-details":"auto","outlined":"","label":"Nome Fantasia"},model:{value:(_vm.filtro.razaoSocial),callback:function ($$v) {_vm.$set(_vm.filtro, "razaoSocial", $$v)},expression:"filtro.razaoSocial"}})],1),_c('InputMaskField',{attrs:{"label":"Filtrar por CNPJ","maskFormat":"##.###.###/####-##","valueField":_vm.filtro.cnpj,"cols":6},on:{"update:value":function($event){_vm.filtro.cnpj = $event}}}),_c('SelectField',{attrs:{"label":"Filtrar por Status","keyValue":"id","keyName":"descricao","items":_vm.listaStatusParceiro,"cols":6,"valueField":_vm.filtro.status},on:{"update:value":function($event){_vm.filtro.status = $event}}})],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){_vm.exibirDialogFiltros = false}}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.aplicarFiltro()
                  _vm.exibirDialogFiltros = false}}},[_vm._v("filtrar")])],1)],1)],1)],1)]},proxy:true},{key:"item.cnpj",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("VMask")(item.cnpj,'##.###.###/####-##')))])]}},{key:"item.produto",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.produto ? 'SIM' : 'NÃO'))])]}},{key:"item.idStatus",fn:function(ref){
                  var item = ref.item;
return [_c('StatusChip',{attrs:{"color":_vm.getColor(item.idStatus)}},[_vm._v(" "+_vm._s(item.descricaoStatus ? item.descricaoStatus : 'NÃO INFORMADO')+" ")])]}},{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [_c('v-dialog',{attrs:{"width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","color":"green","loading":item.idStatus !== 2 && item.atualizando,"disabled":item.idStatus === 2 || item.atualizando}},'v-btn',attrs,false),on),[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"white"}},'v-icon',attrs,false),on),[_vm._v(" mdi-check-circle ")])]}}],null,true)},[_c('span',[_vm._v("Tornar Ativo")])])],1)]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-card-title',{attrs:{"color":"primary"}},[_vm._v(" Tem certeza que deseja alterar o status? ")]),_c('v-divider'),_c('v-card-text',{staticClass:"text-5 body-1 my-6"},[_c('div',{staticClass:"text--primary"},[_vm._v(" "+_vm._s(("Essa ação mudará o status do parceiro " + (item.nome) + " para ATIVO. Isso permitirá seu uso da plataforma de vendas. Deseja continuar? "))+" ")])]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.alterarStatusParceiro(item, 2)
                  dialog.value = false}}},[_vm._v("Confirmar")])],1)],1)]}}],null,true)}),_c('v-dialog',{attrs:{"width":"660"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","color":item.produto ? 'red' : 'blue',"loading":item.ativandoProdutos,"disabled":(item.idStatus !== 2 || item.ativandoProdutos)}},'v-btn',attrs,false),on),[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"white"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(item.produto ? 'mdi-store-remove' : 'mdi-store-check')+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.produto ? 'Desativar' : 'Ativar')+" venda por produtos")])])],1)]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-card-title',{attrs:{"color":"primary"}},[_vm._v(" Tem certeza que deseja alterar a permissão de venda de produtos para este parceiro? ")]),_c('v-divider'),_c('v-card-text',{staticClass:"text-5 body-1 my-6"},[(item.produto)?_c('div',{staticClass:"text--primary"},[_vm._v(" "+_vm._s(("Esta ação removerá a permissão de venda por produtos do parceiro " + (item.nome) + ". Deseja continuar?"))+" ")]):_vm._e(),(!item.produto)?_c('div',{staticClass:"text--primary"},[_vm._v(" "+_vm._s(("Esta ação permitirá que o parceiro " + (item.nome) + " realize venda por produtos. Deseja continuar?"))+" ")]):_vm._e()]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.alterarPermisaoVendaProdutos(item)
                  dialog.value = false}}},[_vm._v("Confirmar")])],1)],1)]}}],null,true)}),_c('v-dialog',{attrs:{"width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"white--text",attrs:{"small":"","color":"red","loading":item.idStatus === 2 && item.atualizando,"disabled":item.idStatus !== 2 || item.atualizando}},'v-btn',attrs,false),on),[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"white"}},'v-icon',attrs,false),on),[_vm._v(" mdi-close-circle ")])]}}],null,true)},[_c('span',[_vm._v("Tornar Inativo")])])],1)]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-card-title',{attrs:{"color":"primary"}},[_vm._v(" Tem certeza que deseja alterar o status? ")]),_c('v-divider'),_c('v-card-text',{staticClass:"text-5 body-1 my-6"},[_c('div',{staticClass:"text--primary"},[_vm._v(" "+_vm._s(("Essa ação mudará o status do parceiro " + (item.nome) + " para INATIVO. Isso revogorá sua permissão de uso da plataforma de vendas. Deseja continuar?"))+" ")])]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.alterarStatusParceiro(item, 3)
                  dialog.value = false}}},[_vm._v("Confirmar")])],1)],1)]}}],null,true)})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }